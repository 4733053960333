import React, { useState, useEffect, Fragment } from "react"
import styled from "styled-components"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { Container, Accordion, Button, Card, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { fetch } from "../../tools/api"
import { removeDuplicates } from "../../tools/tools"

const CustomToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = React.useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <CustomButton
      //   content={isCurrentEventKey ? "\f146" : "\f0fe"}
      onClick={decoratedOnClick}
    >
      {children}
    </CustomButton>
  )
}

const UnorderedList = ({ children }) => {
  return (
    <ul
      style={{
        paddingInlineStart: "40px",
        fontFamily: "Typefez-reg",
      }}
    >
      {children}
    </ul>
  )
}

const LayoutAccordion = ({ children, title }) => {
  return (
    <Accordion id="accordionExample">
      <CustomCard>
        <CustomCardHeader>
          <CustomToggle eventKey="0">
            <CustomTextHeader>{title}</CustomTextHeader>
          </CustomToggle>
        </CustomCardHeader>
        <Accordion.Collapse eventKey="0">
          <CustomCardBody pad="5px 35px" w576="95%" respad="5px 0px 0px 5px">
            {children}
          </CustomCardBody>
        </Accordion.Collapse>
      </CustomCard>
    </Accordion>
  )
}

const SubAccordion = ({ children, title }) => {
  return (
    <Accordion id="accordionExample">
      <CustomCard>
        <CustomCardHeader>
          <CustomToggle eventKey="0">
            <CustomTextHeader2>{title}</CustomTextHeader2>
          </CustomToggle>
        </CustomCardHeader>
        <Accordion.Collapse eventKey="0">
          <CustomCardBody pad="5px 35px" w576="95%" respad="5px 0px 0px 5px">
            {children}
          </CustomCardBody>
        </Accordion.Collapse>
      </CustomCard>
    </Accordion>
  )
}

const QuestionAccordion = ({ children, title }) => {
  return (
    <Accordion id="accordionExample">
      <CustomCard>
        <CustomCardHeader>
          <CustomToggle eventKey="0">
            <CustomTextHeader3>{title}</CustomTextHeader3>
          </CustomToggle>
        </CustomCardHeader>
        <Accordion.Collapse eventKey="0">
          <CustomCardBody pad="5px 35px" w576="95%" respad="5px 0px 0px 5px">
            {children}
          </CustomCardBody>
        </Accordion.Collapse>
      </CustomCard>
    </Accordion>
  )
}

export const AccordionFaq = ({ props, isEnglish }) => {
  const { data, loading } = fetch({ url: "/api/faq/v2_all", type: "io" })

  const array = data?.data || []

  const categoriesExisted = removeDuplicates(
    array.map(item => item?.subcategory?.category?.category_key)
  )

  const sortedByCategories = categoriesExisted.map(category_key => {
    const result = array.filter(
      item => item?.subcategory?.category?.category_key == category_key
    )

    const { category_eng, category_idn } =
      result[0]?.subcategory?.category || {}

    const subCategoriesExisted = removeDuplicates(
      result.map(item => item?.subcategory?.subcategory_key)
    )

    const sortedBySubCategories = subCategoriesExisted.map(sub_category_key => {
      const resultSub = result.filter(
        item => item?.subcategory?.subcategory_key == sub_category_key
      )

      const { subcategory_eng, subcategory_idn } =
        resultSub[0]?.subcategory || {}

      return { subcategory_eng, subcategory_idn, result: resultSub }
    })

    return { category_eng, category_idn, sortedBySubCategories }
  })

  console.log("sortedByCategories:", sortedByCategories)

  return sortedByCategories.map(item => {
    const { category_idn, category_eng, sortedBySubCategories } = item
    const categoryTitle = isEnglish ? category_eng : category_idn
    return (
      <div style={{ marginBottom: 5 }}>
        <div>
          <LayoutAccordion title={categoryTitle}>
            {sortedBySubCategories.map(subCat => {
              const { subcategory_idn, subcategory_eng, result } = subCat
              const subCategory = isEnglish ? subcategory_eng : subcategory_idn
              return (
                <div>
                  <SubAccordion title={subCategory}>
                    <div>
                      {result.map(titles => {
                        const {
                          title_idn,
                          title_eng,
                          content_idn_v2,
                          content_eng_v2,
                        } = titles
                        const title = isEnglish ? title_eng : title_idn
                        const contentV2 = isEnglish
                          ? content_eng_v2
                          : content_idn_v2
                        const itemContent = contentV2.split("\n")
                        const newContent = itemContent.map(item => {
                          if (
                            item.startsWith("<p><a ") &&
                            item.includes("static_image")
                          ) {
                            const newStr = `<img src="${item
                              .split(">")[2]
                              .replace(
                                "</a",
                                '"style="display: block; margin: 0 auto; width: 30%; height: 30%;" />'
                              )}`
                            return newStr.replace(String.fromCharCode(92), "")
                          } else {
                            return item
                          }
                        })
                        const content = newContent.join("\n")
                        return (
                          <div>
                            <QuestionAccordion title={title}>
                              <div
                                dangerouslySetInnerHTML={{ __html: content }}
                                className="ContentText"
                              />
                            </QuestionAccordion>
                          </div>
                        )
                      })}
                    </div>
                  </SubAccordion>
                </div>
              )
            })}
          </LayoutAccordion>
        </div>
      </div>
    )
  })
}

const ContainerAccordion = styled(Container)`
  margin-top: ${p => p.mt};
  padding-left: ${p => p.pl};
  @media (max-width: 576px) {
    margin-top: ${p => p.mtres};
    padding-left: ${p => p.plres};
  }
`

const CustomButton = styled(Button)`
  padding: 0px;
  background-color: transparent;
  border: none;
  :hover,
  :focus,
  :active {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
  }
  ::before {
    transition: all 0.2s linear;
    /* transform: ${p => p.rotate}; */
    font-family: FontAwesome;
    content: "\f0fe";
    font-size: 24px;
    position: absolute;
    left: 4px;
    top: -5px;
    color: #da649f;
  }
`

const CustomCardHeader = styled(Card.Header)`
  background-color: white;
  border: 0px;
  padding: 0px 20px;
`

const CustomCard = styled(Card)`
  border: 0px;
  margin-bottom: 1rem;
`

const CustomTextHeader = styled.h3`
  font-family: "Typefez-black";
  font-size: ${p => p.fsize || "24px"};
  color: #2ebcd0;
  margin-left: 30px;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 992px) {
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 21px;
  }
`
const CustomTextHeader2 = styled.h3`
  font-family: "Typefez-black";
  font-size: ${p => p.fsize || "22px"};
  color: #da649f;
  margin-left: 30px;
  text-align: left;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 576px) {
    font-size: 19px;
  }
`

const CustomTextHeader3 = styled.h3`
  font-family: "Typefez-reg";
  font-size: ${p => p.fsize || "16px"};
  color: #2ebcd0;
  margin-left: 30px;
  margin-bottom: 0px;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 992px) {
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 13px;
  }
`

const CustomCardBody = styled(Card.Body)`
  margin: ${p => p.margin || "15px"};
  width: ${p => p.width || "100%"};
  padding: ${p => p.pad};
  @media (max-width: 992px) {
    width: ${p => p.w992};
  }
  @media (max-width: 768px) {
    width: ${p => p.w768};
  }
  @media (max-width: 576px) {
    width: ${p => p.w576 || "90%"};
    padding: ${p => p.respad};
  }
`
const SubCategoryText = styled.h4`
  font-size: 22px;
  color: #da649f;
  margin-top: 7px;
`

const BodyTitleText = styled.h5`
  font-size: 1rem;
  color: #da649f;
  margin-top: 15px;
`
const BodyDescText = styled.li`
  font-family: "Typefez-reg";
  text-align: justify;
  font-weight: ${p => p.fw}; ;
`
const BodyDescP = styled.p`
  font-family: "Typefez-reg";
  text-align: justify;
  margin-top: 5px;
  padding-left: ${p => p.pleft};
`

const BodyDescTextBold = styled.span`
  font-weight: bold;
  font-family: "Typefez-reg";
`

const BodyDescTextItalic = styled.span`
  font-style: italic;
`

const BodyTextLink = styled(Link)`
  color: #da649f;
  cursor: pointer;
  text-decoration: none;
`
const BodyTextA = styled.a`
  color: #da649f;
  cursor: pointer;
  text-decoration: none;
`
