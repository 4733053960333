import React from "react"
import { AccordionFaq } from "./AccordionFaq"
import bgCloud from "../../images/bg-cloud2.svg"
import ornament from "../../images/ornament-bird-big.png"
import FaqContactUs from "../FaqSection/Faq-ContactUs"
import {
  FaqMainContent,
  FaqContainer,
  FaqQuestionWrapper,
  OrnamentImg,
} from "../FaqSection/FaqStyle"
import styled from "styled-components"
import { Container } from "react-bootstrap"

export const SgFaqBody = ({ props, isEnglish, propsLink }) => {
  return (
    <>
      <AccordionFaq props={props} isEnglish={isEnglish} />
      <FaqContactUs props={props} propsLink={propsLink} />
    </>
  )
}

const ContainerAccordion = styled(Container)`
  padding: 50px 30px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  @media (max-width: 576px) {
    padding: 50px 10px;
  }
`
