import React from "react"
import "../layout.css"
import { useLocation } from "@reach/router"
import { NavigationBar } from "../NavigationBar/parent"
import { MainPageJson, PathJson, SingaporeFaqJson } from "../location"
import Footer from "../FooterComponents/Footer"
import { SgFaqHeader } from "./SgFaqHeader"
import Seo from "../SEOComponents/Seo"
import { SgFaqBody } from "./SgFaqBody"
import { LayoutTextPage } from "../LayoutTextPage/parent"

function SingaporeFaq() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const FaqSgContent = SingaporeFaqJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title, Desc } = FaqSgContent.Header || {}
  return (
    <div className="maxwidth">
      <Seo title="faq" />
      <NavigationBar />
      <LayoutTextPage title={Title} desc={Desc}>
        <SgFaqBody
          props={FaqSgContent}
          isEnglish={isEnglish}
          propsLink={PathContent}
        />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SingaporeFaq
